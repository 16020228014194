<template>
  <v-dialog
    width="800"
    v-model="dialog"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card :loading="loading">
      <v-system-bar window>
        {{ title }}
        <v-spacer />
        <v-toolbar-items
          ><v-icon @click="close()">mdi-close</v-icon></v-toolbar-items
        >
      </v-system-bar>
      <v-card-text class="ma-0 pa-0" v-if="item">
        <v-container>
          <v-row align="center">
            <v-col cols="auto grow">
              <PersonInput
                hide-details
                v-if="isNew && !$_hasRole('student')"
                v-model="item.student"
                :group="schoolClass.code"
              />
              <PersonItem v-else :value="item.student" />
            </v-col>
            <v-col cols="grow"
              ><v-btn-toggle
                v-model="isHalfday"
                dense
                mandatory
                class="d-flex flex-row"
              >
                <v-btn text :value="false" class="flex-grow-1">
                  Entschuldigung
                </v-btn>
                <v-btn text :value="true" class="flex-grow-1"> Halbtag </v-btn>
              </v-btn-toggle></v-col
            >
          </v-row>

          <template v-if="isHalfday">
            <v-row align="center">
              <v-col cols="auto grow">
                <DateInput v-model="item.startDate" label="Tag" />
              </v-col>
              <v-col>
                <v-btn-toggle
                  dense
                  v-model="timeOfDay"
                  mandatory
                  class="d-flex flex-row"
                >
                  <v-btn text value="halfDayMorning" class="flex-grow-1"
                    >Vormittag</v-btn
                  >
                  <v-btn text value="halfDayAfternoon" class="flex-grow-1"
                    >Nachmittag</v-btn
                  >
                </v-btn-toggle>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="auto">
                <v-switch inset v-model="allDay">
                  <template v-slot:label> ganztägig </template>
                  <template v-slot:append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-help-circle-outline</v-icon
                        >
                      </template>
                      <span
                        >Die Entschuldigung gilt von morgens früh bis abends
                        spät!</span
                      >
                    </v-tooltip>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="auto grow">
                <v-row>
                  <v-col>
                    <DateInput
                      v-model="item.startDate"
                      label="von Tag"
                      required
                    />
                  </v-col>
                  <v-col>
                    <TimeInput
                      :disabled="allDay"
                      :label="allDay ? 'morgens' : 'ab'"
                      v-model="item.startTime"
                      :required="!allDay"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <DateInput
                      v-model="item.endDate"
                      label="bis Tag"
                      required
                    />
                  </v-col>
                  <v-col>
                    <TimeInput
                      :disabled="allDay"
                      :label="allDay ? 'abends' : 'bis'"
                      v-model="item.endTime"
                      :required="!allDay"
                    />
                  </v-col>
                </v-row>
                <v-alert text type="error" v-if="dateError"
                  >Der Beginn der Entschuldigung darf nicht vor dem Ende dieser
                  liegen!</v-alert
                >
              </v-col>
            </v-row>

            <LookupValueInput
              v-model="item.category"
              :items="categories"
              label="Grund"
              ><template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"
                      >mdi-help-circle-outline</v-icon
                    >
                  </template>
                  <span>Abwesenheitsgrund</span>
                </v-tooltip></template
              >
            </LookupValueInput>
          </template>
          <v-row>
            <v-col cols="12">
              <v-textarea
                :disabled="!$_hasRole('student')"
                v-model="item.description"
                outlined
                label="Entschuldigungstext Schüler:in"
                :rules="rules"
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <span>
                      <span v-if="isNew && !$_hasRole('student')"
                        >Kann anschliessend von dem oder der Schüler:in erfasst
                        werden.</span
                      >
                      <span v-else-if="!$_hasRole('student')"
                        >Kann nur von dem oder der Schüler:in bearbeitet
                        werden.</span
                      >
                      <span v-else
                        >Vertrauliche Mitteilung an
                        Klassenlehrperson/Schulleitung</span
                      >
                    </span>
                  </v-tooltip></template
                >
              </v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-if="$_hasRole('teacher')"
                v-model="item.comments"
                label="Kommentar Klassenlehrkraft"
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>

                    <span>Wird nur der Klassenlehrkraft angezeigt. </span>
                  </v-tooltip></template
                >
              </v-text-field>
            </v-col>
          </v-row>

          <RegisterLessons
            showAbsenceToggleButton
            :person="item.student"
            :startDate="item.startDate"
            :startTime="item.startTime"
            :endDate="item.endDate"
            :endTime="item.endTime"
          />

          <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>

          <v-alert
            v-if="justifications.length > 0"
            type="error"
            border="left"
            text
            prominent
          >
            Es gibt Konflikte mit den folgenden Entschuldigungen/Halbtagen:
            <v-list light>
              <JustificationItem
                v-for="justification in justifications"
                :key="`justification${justification.id}`"
                :value="justification"
              />
            </v-list>
          </v-alert>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn @click="close(false)" text>Abbrechen</v-btn>
        <v-spacer />
        <v-btn
          v-if="item.canDelete"
          @click="trash()"
          color="danger"
          text
          class="mr-2"
          >Löschen</v-btn
        >
        <v-btn
          @click="save()"
          :disabled="!saveable"
          color="primary"
          text
          :loading="checking"
          >Prüfen & Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { checkDatespan } from "common/utils/date.js";

import RegisterLessons from "common/components/Register/RegisterLessons.vue";
import JustificationItem from "@/components/JustificationItem.vue";
import DateInput from "common/components/DateInput.vue";
import PersonInput from "common/components/PersonInput.vue";
import PersonItem from "@/components/PersonItem.vue";
import TimeInput from "common/components/TimeInput.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";

export default {
  name: "JustificationEdit",
  components: {
    RegisterLessons,
    JustificationItem,
    DateInput,
    LookupValueInput,
    PersonInput,
    PersonItem,
    TimeInput,
  },
  props: ["justificationId", "schoolClass", "student"],
  data() {
    return {
      allDay: true,
      isHalfday: false,
      checking: false,
      timeOfDay: "halfDayMorning",
      justifications: [],
      categories: [],
      types: [],
      dialog: true,
      errorMessage: null,
      noJustifications: false,
      loading: false,
      item: {
        startDate: null,
        endDate: null,
        type: 1,
      },
      rules: [
        (value) => {
          return (
            !this.hasEmoji(value) || "Bitte keine Emojis in Entschuldigungen."
          );
        },
      ],
    };
  },
  computed: {
    dateError() {
      return checkDatespan(
        this.item.startDate,
        this.item.startTime,
        this.item.endDate,
        this.item.endTime
      );
    },

    isNew() {
      return this.justificationId == 0;
    },

    saveable() {
      if (this.hasEmoji(this.item.description)) {
        return false;
      }
      if (this.isHalfday) {
        return (
          this.item.student &&
          this.item.startDate &&
          this.item.endDate &&
          !this.dateError
        );
      }
      if (this.allDay) {
        return (
          this.item.student &&
          this.item.category &&
          this.item.startDate &&
          this.item.endDate &&
          !this.dateError
        );
      }
      return (
        this.item.student &&
        this.item.category &&
        this.item.startDate &&
        this.item.startTime &&
        this.item.endDate &&
        this.item.endTime &&
        !this.dateError
      );
    },
    title() {
      return (
        (this.isHalfday ? "Halbtag" : "Entschuldigung") +
        " " +
        (this.isNew ? "erstellen" : "bearbeiten")
      );
    },
  },
  watch: {
    "item.startDate": function () {
      if (!this.item.endDate) {
        this.item.endDate = this.item.startDate;
      }
    },
    allDay() {
      if (this.allDay) {
        this.item.startTime = null;
        this.item.endTime = null;
      }
    },
    justificationId() {
      this.fetchData();
    },
    isHalfday() {
      this.setType();
      if (this.isHalfday) {
        this.item.endDate = this.item.startDate;
      }
    },
    timeOfDay() {
      this.setType();
    },
  },
  methods: {
    checkDatespan,
    hasEmoji(text) {
      var pattern = /\p{Extended_Pictographic}/u;
      return pattern.test(text);
    },
    setType() {
      if (this.isHalfday) {
        this.allDay = false;
        this.item.type = this.types.find((el) => el.code == this.timeOfDay);
        this.item.startTime = this.item.type.startTime;
        this.item.endTime = this.item.type.endTime;
      } else {
        this.item.type = this.types.find((el) => el.code == "excuse");
      }
    },
    async check() {
      this.checking = true;
      this.noJustifications = false;

      this.justifications = (
        await this.apiList({
          resource: "absence/justification",
          query: `startDate=${this.item.startDate}&startTime=${this.item.startTime}&endDate=${this.item.endDate}&endTime=${this.item.endTime}&student=${this.item.student.id}`,
        })
      ).filter(
        (el) => el.id != this.justificationId && el.status.code != "deleted"
      );

      this.checking = false;
      return this.justifications.length == 0;
    },
    close() {
      this.$router.back();
    },
    detail() {
      if (this.$_hasRole("student")) {
        this.$router.push({
          name: "JustificationStudentDetail",
          params: { justificationId: this.item.id },
        });
      } else if (this.$route.name == "JustificationPendingEdit") {
        this.$router.push({
          name: "JustificationPendingDetail",
          params: { justificationId: this.item.id },
        });
      } else {
        this.$router.push({
          name: "JustificationOverviewDetail",
          params: { id: this.item.student.id, justificationId: this.item.id },
        });
      }
    },
    async save() {
      this.errorMessage = null;
      if (await this.check()) {
        const item = await this.apiSave({
          add: this.isNew,
          resource: "absence/justification",
          data: this.item,
          onError: (response) => (this.errorMessage = response),
        });
        if (item) {
          this.item = item;
        }
        this.$emit("updated");
        this.detail();
      }
    },
    async trash() {
      if (
        await this.$root.confirm({
          message: `Soll diese Entschuldigung gelöscht werden?`,
          color: "error",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "absence/justification",
          id: this.item.id,
        });
        this.$emit("updated");
        this.$route.push;
      }
    },
    async fetchData() {
      this.loading = true;

      this.categories = await this.apiList({
        resource: "absence/justificationcategory",
      });

      this.types = await this.apiList({
        resource: "absence/justificationtype",
      });

      if (!this.isNew) {
        this.item = await this.apiGet({
          resource: "absence/justification",
          id: this.justificationId,
        });
        this.allDay = !this.item.startTime && !this.item.endTime;
        this.isHalfday = this.item.type.code != "excuse";
        if (this.isHalfday) {
          this.timeOfDay = this.item.type.code;
        }
      } else {
        this.item = {
          type: this.types.find((el) => el.code == "excuse"),
          student: this.student,
          startDate: "",
          endDate: "",
          startTime: null,
          endTime: null,
          description: "",
        };
      }
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
