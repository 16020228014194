<template>
  <v-list-item>
    <v-list-item-avatar
      ><PortraitImage :value="value.student"
    /></v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <PersonName :value="value.student" /> ({{ value.type.description }})
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formatDatespan(value.startDate, value.endDate, true) }},
        {{
          formatTimespan(
            value.startDate,
            value.startTime,
            value.endDate,
            value.endTime
          )
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-chip outlined>{{ value.lessonCount }} Lektionen</v-chip>
  </v-list-item>
</template>

<script>
import PersonName from 'common/components/PersonName.vue';
import PortraitImage from 'common/components/PortraitImage.vue';

import { formatDatespan, formatTimespan } from 'common/utils/date.js';

export default {
  components: { PersonName, PortraitImage },
  props: ['value'],
  methods: { formatDatespan, formatTimespan },
};
</script>
